export const pentestType = [
  { text: 'External Network', value: 'external' },
  { text: 'Internal Network', value: 'internal' },
  { text: 'Web Application', value: 'webapp' },
  { text: 'API / Web Service', value: 'api' },
  { text: 'Mobile Application', value: 'mobile' },
  { text: 'Medical Device', value: 'medical' },
  { text: 'SCADA / ICS', value: 'scada' },
  { text: 'Source Code Review', value: 'code review' },
  { text: 'Phishing Simulation', value: 'phishing' }
]

export const phasesOptions = [
  { text: "External", value: "external", color: '#0070C0' },
  { text: "Internal", value: "internal", color: '#FCCB65' },
  { text: "Web Application", value: "webapp", color: '#63CB74' },
  // { text: "Web API", value: "api", color: '#63CB74' },
  { text: "Mobile", value: "mobile", color: '#28AAD2' },
  { text: "Scada", value: "scada", color: '#EC574D' },
  { text: "IoT", value: "iot", color: '#7328D2' }
];

export const tagPhasesOptions = [
  { text: "Web API", value: "api", color: '#63CB74' },
  { text: 'Medical Device', value: 'medical', color: '#28AAD2' },
  { text: 'Source Code Review', value: 'code review', color: '#28AAD2' },
  { text: 'Phishing Simulation', value: 'phishing', color: '#28AAD2' },
  { text: "AWS Infra Audit", value: "audit_aws", color: '#63CB74' },
  { text: "Azure Infra Audit", value: "audit_azure", color: '#63CB74' },
  { text: "GCP Infra Audit", value: "audit_gcp", color: '#63CB74' },
  { text: "Internal & External", value: "infra_both", color: '#63CB74' }
]

export const simplePentestType = [
  { text: 'External Network Penetration Test', value: 'external' },
  { text: 'Internal Network Penetration Test', value: 'internal' },
  { text: 'Web Application Penetration Test', value: 'webapp' },
  { text: 'API / Web Service Pentest Penetration Test', value: 'api' },
  { text: 'Mobile Application Penetration Test', value: 'mobile' },
  { text: 'Medical Device Pentest Penetration Test', value: 'medical' },
  { text: 'SCADA / ICS Pentest Penetration Test', value: 'scada' },
  { text: 'Source Code Review Penetration Test', value: 'code review' },
  { text: 'Phishing Simulation Penetration Test', value: 'phishing' }
]

export const assetTypes = [
  { text: 'Mobile application', value: 'mobile' },
  { text: 'Network', value: 'network' },
  { text: 'Other', value: 'other' },
  { text: 'IOT Device', value: 'iot' },
  { text: 'Web API', value: 'api' },
  { text: 'Web application', value: 'app' }
]

export const assetTechnos = [
  'Ngnix',
  'Apache',
  'JavaScript',
  'TypeScript',
  'Node.Js',
  'PostgreSQL',
  'MongoDB',
  'Python',
  'PHP'
]

export const providers = [
  { text: 'Google', value: 'google' },
  { text: 'Internal', value: 'internal' },
  { text: 'Microsoft', value: 'azure' }
]

export const providersOrUnspecified = [
  { text: 'Google', value: 'google' },
  { text: 'Internal', value: 'internal' },
  { text: 'Microsoft', value: 'azure' },
  { text: 'Unspecified', value: 'null' }
]

export const userRoles = [
  { text: 'User', value: 'Client User' },
  { text: 'Manager', value: 'Client Admin' }
]

export const allRoles = [
  { text: 'User', value: 'Client User' },
  { text: 'Manager', value: 'Client Admin' },
  { text: 'Super User', value: 'Superuser' }
]

export const selectIconFileType = [
  { value: 'mdi-microsoft-word', text: 'docx', color: 'primary' },
  { value: 'mdi-file-jpg-box', text: 'jpg', color: 'primary' },
  { value: 'mdi-file-jpg-box', text: 'jpe', color: 'primary' },
  { value: 'mdi-file-jpg-box', text: 'jpeg', color: 'primary' },
  { value: 'mdi-file-png-box', text: 'png', color: 'primary' },
  { value: 'mdi-file-pdf-box', text: 'pdf', color: 'error' },
  { value: 'mdi-file-table-outline', text: 'csv', color: '' },
  { value: 'mdi-microsoft-excel', text: 'xlsm', color: 'success' },
  { value: 'mdi-microsoft-excel', text: 'xlsx', color: 'success' },
  { value: 'mdi-application-outline', text: 'exe', color: 'orange darken-3' },
  { value: 'mdi-console', text: 'bat', color: 'orange darken-3' },
  { value: 'mdi-zip-box', text: 'zip', color: 'warning' },
  { value: 'mdi-language-python', text: 'py', color: 'primary' },
  { value: 'mdi-language-html5', text: 'html', color: 'orange darken-3' },
  { value: 'mdi-console', text: 'sh', color: 'orange darken-3' },
  { value: 'mdi-console', text: 'bash', color: 'orange darken-3' },
  { value: 'mdi-file-document-outline', text: 'txt', color: '' },
  { value: 'mdi-svg', text: 'svg', color: 'warning' },
  { value: 'mdi-language-javascript', text: 'js', color: 'yellow ' },
  { value: 'mdi-language-markdown-outline', text: 'md', color: 'pink ' },
  { value: 'mdi-microsoft-powerpoint', text: 'pps', color: 'orange darken-3' },
  { value: 'mdi-microsoft-powerpoint', text: 'ppt', color: 'orange darken-4' },
  { value: 'mdi-file-music-outline', text: 'mp3', color: 'primary' },
  { value: 'mdi-multimedia', text: 'mp4', color: 'primary' },
  { value: 'mdi-multimedia', text: 'mpg', color: 'primary' },
  { value: 'mdi-multimedia', text: 'mpe', color: 'primary' },
  { value: 'mdi-multimedia', text: 'mpeg', color: 'primary' },
  { value: 'mdi-disc', text: 'iso', color: '' },
  { value: 'mdi-file-image-outline', text: 'ico', color: 'teal' },
  { value: 'mdi-file-image-outline', text: 'gif', color: 'teal' },
  { value: 'mdi-file-image-outline', text: 'bmp', color: 'teal' },
  { value: 'mdi-file-code-outline', text: 'xml', color: 'success' }
]

export const allStatus = [
  { text: 'N/A', value: '', icon: 'mdi-progress-question' },
  { text: 'Created', value: 'Created', icon: 'mdi-creation-outline', num: 1 },
  { text: 'Created', value: 'pending', icon: 'mdi-creation-outline', num: 1 },
  { text: 'Ready to Start', value: 'Ready to Start', icon: 'mdi-timer-play-outline', num: 2 },
  { text: 'Ready to Start', value: 'scheduled', icon: 'mdi-timer-play-outline', num: 2 },
  { text: 'Testing', value: 'Testing', icon: 'mdi-test-tube', num: 3 },
  { text: 'Reporting', value: 'Reporting', icon: 'mdi-feather', num: 4 },
  { text: 'Reporting', value: 'in progress', icon: 'mdi-feather', num: 4 },
  { text: 'Report Sent', value: 'in redaction', icon: 'mdi-file-send-outline', num: 4 },
  { text: 'Report Ready', value: 'Report Ready', icon: 'mdi-file-document-check-outline', num: 7 },
  { text: 'Report Sent', value: 'Report Sent', icon: 'mdi-file-send-outline', num: 7 },
  { text: 'Done', value: 'completed', icon: 'mdi-check', num: 7 },
  { text: 'Done', value: 'Done', icon: 'mdi-check', num: 7 }
]

export const status = [
  { text: 'Done', value: 'Done' },
  { text: 'Report Sent', value: 'Report Sent' },
  { text: 'Report Ready', value: 'Report Ready' },
  { text: 'Reporting', value: 'Reporting' },
  { text: 'Testing', value: 'Testing' },
  { text: 'Ready to Start', value: 'Ready to Start' },
  { text: 'Created', value: 'Created' }
]

export const clientCountry = [
  { text: 'Canada', value: 'CA' },
  { text: 'United-States', value: 'US' },
  { text: 'Mexico', value: 'MX' }
]

export const clientIndustry = [
  { text: 'Automotive', value: 'automotive' },
  { text: 'Business Support & Supplies', value: 'business support & supplies' },
  { text: 'Computers & Electronics', value: 'computers & electronics' },
  { text: 'Construction & Contractors', value: 'construction & contractors' },
  { text: 'Education ', value: 'education' },
  { text: 'Entertainment', value: 'entertainment' },
  { text: 'Food & Dining', value: 'food & dining' },
  { text: 'Government', value: 'government' },
  { text: 'Health & Medicine', value: 'health & medicine' },
  { text: 'Home & Garden', value: 'construction & contractors' },
  { text: 'Legal & Financial', value: 'legal & financial' },
  { text: 'Manufacturing, Wholesale, Distribution', value: 'manufacturing, wholesale, distribution' },
  { text: 'Merchants (Retail)', value: 'merchants' },
  { text: 'Miscellaneous', value: 'miscellaneous' },
  { text: 'Personal Care & Services', value: 'personal care & services' },
  { text: 'Real Estate', value: 'real estate' }
]

export const subscriptionSources = {
  azure: 'azure',
  aws: 'aws',
  internal: 'internal'
}

export const loglevel = [
  { text: 'Info', value: 'INFO' },
  { text: 'Warning', value: 'WARNING' },
  { text: 'Critical', value: 'CRITICAL' },
  { text: 'Error', value: 'ERROR' }
]

export const contactCategory = [
  { text: 'Service requests', value: 'Service requests' },
  { text: 'Incidents', value: 'Incidents' },
  { text: 'Problems', value: 'Problems' },
  { text: 'Changes', value: 'Changes' },
  { text: 'Post-incident reviews', value: 'Post-incident reviews' }
]

export const teams = [
  { text: 'Vumetric', value: 'vumetric' },
  { text: 'Telus', value: 'telus' }
]

export const languages = [
  { text: 'Français', value: 'fr' },
  { text: 'English', value: 'en' }
]

export const documentTypes = [
  { text: 'Attestation', value: 'attestation' },
  { text: 'Detailed Report', value: 'report_detailed' },
  { text: 'Other Document', value: 'Document' },
  { text: 'Proposal', value: 'proposal' },
  { text: 'Vulnerability', value: 'notification' },
  { text: 'Summary Report', value: 'report_summary' }
]

export const docTypes = {
  attestation: 'Attestation',
  report_detailed: 'Detailed Report',
  document: 'Document',
  proposal: 'Proposal',
  notification: 'Vulnerability',
  report_summary: 'Summary Report'
}

export const reportTypes = {
  attestation: 'Attestation',
  report_detailed: 'Detailed Report',
  notification: 'Vulnerability',
  report_summary: 'Summary Report'
}

export const countries = [
  "United States",
  "Canada",
  "Afghanistan",
  "Albania",
  "Algeria",
  "Andorra",
  "Angola",
  "Antigua & Deps",
  "Argentina",
  "Armenia",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bhutan",
  "Bolivia",
  "Bosnia Herzegovina",
  "Botswana",
  "Brazil",
  "Brunei",
  "Bulgaria",
  "Burkina",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cape Verde",
  "Central African Rep",
  "Chad",
  "Chile",
  "China",
  "Colombia",
  "Comoros",
  "Congo",
  "Congo {Democratic Rep}",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "East Timor",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Ethiopia",
  "Fiji",
  "Finland",
  "France",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Greece",
  "Grenada",
  "Guatemala",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Honduras",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran",
  "Iraq",
  "Ireland {Republic}",
  "Israel",
  "Italy",
  "Ivory Coast",
  "Jamaica",
  "Japan",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea North",
  "Korea South",
  "Kosovo",
  "Kuwait",
  "Kyrgyzstan",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macedonia",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Mauritania",
  "Mauritius",
  "Mexico",
  "Micronesia",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Morocco",
  "Mozambique",
  "Myanmar, {Burma}",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Poland",
  "Portugal",
  "Qatar",
  "Romania",
  "Russian Federation",
  "Rwanda",
  "St Kitts & Nevis",
  "St Lucia",
  "Saint Vincent & the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome & Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "South Sudan",
  "Spain",
  "Sri Lankaﬂ",
  "Sudan",
  "Suriname",
  "Swaziland",
  "Sweden",
  "Switzerland",
  "Syria",
  "Taiwan",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Togo",
  "Tonga",
  "Trinidad & Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Vatican City",
  "Venezuela",
  "Vietnam",
  "Yemen",
  "Zambia",
  "Zimbabwe"
]

export const subscriptionPlans = {
  "custom_quote": { name: "Penetration test", description: "Experts-Driven Pentest • Critical Vulnerabilities Retest Included • Fast Execution & Report Delivery • Certified Team • Leading Methodology & Standards PTES, MITRE ATT&CK, OSSTMM, CVE, CVSS, etc. • Attestation of Compliance for SOC2, ISO27001, PCI DSS etc." },
  "penetration-test": { name: "Penetration test", description: "Experts-Driven Pentest • Critical Vulnerabilities Retest Included • Fast Execution & Report Delivery • Certified Team • Leading Methodology & Standards PTES, MITRE ATT&CK, OSSTMM, CVE, CVSS, etc. • Attestation of Compliance for SOC2, ISO27001, PCI DSS etc." },
  "external_micro": { name: "Infrastructure Pentest - Micro", description: "1-10 Public IP Address - Experts-Driven Pentest • Critical Vulnerabilities Retest Included • Fast Execution & Report Delivery • Certified Team • Leading Methodology & Standards PTES, MITRE ATT&CK, OSSTMM, CVE, CVSS, etc. • Attestation of Compliance for SOC2, ISO27001, PCI DSS etc." },
  "external_small": { name: "Infrastructure Pentest - Small", description: "11-25 Public IP Address - Experts-Driven Pentest • Critical Vulnerabilities Retest Included • Fast Execution & Report Delivery • Certified Team • Leading Methodology & Standards PTES, MITRE ATT&CK, OSSTMM, CVE, CVSS, etc. • Attestation of Compliance for SOC2, ISO27001, PCI DSS etc." },
  "external_medium": { name: "Infrastructure Pentest - Medium", description: "26-50 Public IP Address - Experts-Driven Pentest • Critical Vulnerabilities Retest Included • Fast Execution & Report Delivery • Certified Team • Leading Methodology & Standards PTES, MITRE ATT&CK, OSSTMM, CVE, CVSS, etc. • Attestation of Compliance for SOC2, ISO27001, PCI DSS etc." },
  "external_large": { name: "Infrastructure Pentest - Large", description: "51-100 Public IP Address - Experts-Driven Pentest • Critical Vulnerabilities Retest Included • Fast Execution & Report Delivery • Certified Team • Leading Methodology & Standards PTES, MITRE ATT&CK, OSSTMM, CVE, CVSS, etc. • Attestation of Compliance for SOC2, ISO27001, PCI DSS etc." },
  "external_xlarge": { name: "Infrastructure Pentest - X-Large", description: "101-250 Public IP Address - Experts-Driven Pentest • Critical Vulnerabilities Retest Included • Fast Execution & Report Delivery • Certified Team • Leading Methodology & Standards PTES, MITRE ATT&CK, OSSTMM, CVE, CVSS, etc. • Attestation of Compliance for SOC2, ISO27001, PCI DSS etc." },
  "external_2xlarge": { name: "Infrastructure Pentest - 2X-Large", description: "251-500 Public IP Address - Experts-Driven Pentest • Critical Vulnerabilities Retest Included • Fast Execution & Report Delivery • Certified Team • Leading Methodology & Standards PTES, MITRE ATT&CK, OSSTMM, CVE, CVSS, etc. • Attestation of Compliance for SOC2, ISO27001, PCI DSS etc." },
  "app_basic": { name: "Application Pentest - Basic", description: "Unauthenticated Testing (Black Box) - Experts-Driven Pentest • Critical Vulnerabilities Retest Included • In-depth Testing, Business logic, OWASP & SANS Top 25 Coverage • Fast Execution & Report Delivery • Certified Team • Attestation of Compliance for SOC2, ISO27001, PCI DSS etc." },
  "app_modern": { name: "Application Pentest - Modern", description: "Authenticated Testing - Up to 1 User Role - Experts-Driven Pentest • Critical Vulnerabilities Retest Included • In-depth Testing, Business logic, OWASP & SANS Top 25 Coverage • Fast Execution & Report Delivery • Certified Team • Attestation of Compliance for SOC2, ISO27001, PCI DSS etc." },
  "app_business": { name: "Application Pentest - Business", description: "Authenticated Testing - Up to 3 User Roles - Experts-Driven Pentest • Critical Vulnerabilities Retest Included • In-depth Testing, Business logic, OWASP & SANS Top 25 Coverage • Fast Execution & Report Delivery • Certified Team • Attestation of Compliance for SOC2, ISO27001, PCI DSS etc." },
  "app_enterprise": { name: "Application Pentest - Enterprise", description: "Authenticated Testing - Up to 5 User Roles - Experts-Driven Pentest • Critical Vulnerabilities Retest Included • In-depth Testing, Business logic, OWASP & SANS Top 25 Coverage • Fast Execution & Report Delivery • Certified Team • Attestation of Compliance for SOC2, ISO27001, PCI DSS etc." },
  "app_startup": { name: "Application Pentest – Startup*", description: "Authenticated Testing - Up to 1 User Role - Experts-Driven Pentest • Critical Vulnerabilities Retest Included • In-depth Testing, Business logic, OWASP & SANS Top 25 Coverage • Fast Execution & Report Delivery • Certified Team • Attestation of Compliance for SOC2, ISO27001, PCI DSS etc." },
  "website_standard": { name: "Website Pentest - Standard", description: "Non-transactional Website / Unauthenticated Testing - Experts-Driven Pentest • Critical Vulnerabilities Retest Included • In-depth Testing, Business logic, OWASP & SANS Top 25 Coverage • Fast Execution & Report Delivery • Certified Team • Attestation of Compliance for SOC2, ISO27001, PCI-DSS, etc." },
  "website_business": { name: "Website Pentest - Business", description: "Business Website / With Workflows (Multi-Step Forms) - Experts-Driven Pentest • Critical Vulnerabilities Retest Included • In-depth Testing, Business logic, OWASP & SANS Top 25 Coverage • Fast Execution & Report Delivery • Certified Team • Attestation of Compliance for SOC2, ISO27001, PCI-DSS, etc." },
  "website_ecomm": { name: "Website Pentest - E-Commerce", description: "E-Commerce Website - Experts-Driven Pentest • Critical Vulnerabilities Retest Included • In-depth Testing, Business logic, OWASP, SANS Top 25, Coverage • Fast Execution & Report Delivery • Certified Team • Attestation of Compliance for SOC2, ISO27001, PCI-DSS, etc." }
}
